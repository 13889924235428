import Slika1 from "../../slike/42.jpg";
import Slika2 from "../../slike/101.jpg";
import Slika3 from "../../slike/217.jpg";
import Slika4 from "../../slike/236.jpg";
import Slika5 from "../../slike/258.jpg";
import Slika6 from "../../slike/259.jpg";
import Slika7 from "../../slike/260.jpg";
import Slika8 from "../../slike/261.jpg";
import Slika9 from "../../slike/268.jpg";
import Slika10 from "../../slike/278.jpg";
import Slika11 from "../../slike/298.jpg";
import Slika12 from "../../slike/302.jpg";
import Slika13 from "../../slike/312.jpg";
import Slika14 from "../../slike/325.jpg";
import Slika15 from "../../slike/334.jpg";
import Slika16 from "../../slike/335.jpg";
import Slika17 from "../../slike/337.jpg";
import Slika18 from "../../slike/358.jpg";
import Slika19 from "../../slike/369.jpg";
import Slika20 from "../../slike/Grb-Vojinovica.png";
import Slika21 from "../../slike/stari-grad1.png";
import Slika22 from "../../slike/stari-grad2.jpg";
import Slika23 from "../../slike/nikola.jpg";
import Slika24 from  "../../slike/skica1.png";
import Slika25 from "../../slike/skica2.png";
import Slika26 from "../../slike/skica3.png";
import Slika27 from "../../slike/skica4.png";

const GalerijaLista = [
    {
        id: 1,
        slika: Slika1,
        tekst:"Поглед на Ужички град са истока на фотографији насталој током радова на изградњи старог железничког моста и новог Златиборског пута насталој двадесетих година 20. века",
        tekstEn:"A view of the Old Town in the photo taken from the east side during the construction of the railway bridge and new Zlatibor road in the 1920s",
        tekstDe:"Blick auf die Altstadt von Osten auf einem Foto, das während des Baues der Eisenbahnbrücke und dem neuen Zlatibor-Weg aufgenommen wurde, aufgenommen in den 1920er Jahren",
        tekstRu:"Вид на Старый город с востока на фотографии, сделанной во время строительства железнодорожного моста и новой Златиборской дороги, сделанной в 1920-х годах",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 2,
        slika: Slika2,
        tekst:"Стара хидроцентрала и Ужички град на фотографији са почетка 20. века",
        tekstEn:"The Old Hydroelectric Power Plant and City of Užice in the photo taken at the beginning of the 20th century",
        tekstDe:"Das Alte Wasserkraftwerk und die Stadt Užice auf einem Foto vom Anfang des 20. Jahrhunderts",
        tekstRu:"Старая ГЭС и город Ужице на фото начала 20 века.",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 3,
        slika: Slika3,
        tekst:"Поглед на Ужички град и варош  са југозапада  на фотографији насталој између два светска рата",
        tekstEn:"A view of the City of Užice in the photo taken from the southwest side between the two world wars",
        tekstDe:"Blick auf die Stadt Užice von Südwesten auf einem Foto, das zwischen den beiden Weltkriegen aufgenommen wurde",
        tekstRu:"Вид на крепость Ужице с юго-запада на фотографии, сделанной между двумя мировыми войнами",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 4,
        slika: Slika4,
        tekst:"Поглед на рушевине Водене куле Ужичког града, Стару хидроцентралу и варош са југозапада на фотографији са почетка 20. века",
        tekstEn:"A view of the ruins of the Water Tower of the City of Užice, Old Hydroelectric Power Plant, and the town in the photo taken from the southwest side at the beginning of the 20th century",
        tekstDe:"Blick auf die Ruine des Wasserturms von Užice, des Alten Wasserkraftwerks und der Stadt von Südwesten auf einem Foto vom Anfang des 20. Jahrhunderts",
        tekstRu:"Вид на руины Водонапорной башни Ужице, старую ГЭС и город с юго-запада на фото начала 20 века",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"portret"

    },
    {
        id: 5,
        slika: Slika5,
        tekst:"Поглед на Ужички град и варош на фотографији насталој тридесетих година 20. века",
        tekstEn:"A view of the City of Užice in the photo taken during the 1930s",
        tekstDe:"Blick auf die Stadt Užice auf einem Foto, die in den dreißiger Jahren des 20. Jahrhunderts aufgenommen wurde",
        tekstRu:"Вид на крепость и город Ужице на фотографии 30-х годов 20 века",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 6,
        slika: Slika6,
        tekst:"Поглед на Стари град на фотографији насталој након изградње старог железничког моста насталој двадесетих година 20. века",
        tekstEn:"A view of the Old Town in the photo taken after the construction of the old railway bridge in the 1920s",
        tekstDe:"Blick auf die Altstadt auf einem Foto, das nach dem Bau der alten Eisenbahnbrücke aufgenommen wurde, aufgenommen in den 1920er Jahren",
        tekstRu:"Вид на Старый город на фотографии, сделанной после строительства старого железнодорожного моста в 1920-е годы",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"portret"
    },
    {
        id: 7,
        slika: Slika7,
        tekst:"Поглед на Ужички град и варош на фотографији насталој тридесетих година 20. века",
        tekstEn:"A view of the City of Užice in the photo taken during the 1930s",
        tekstDe:"Blick auf die Stadt Užice auf einem Foto, die in den dreißiger Jahren des 20. Jahrhunderts aufgenommen wurde",
        tekstRu:"Вид на крепость и город Ужице на фотографии 30-х годов 20 века",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 8,
        slika: Slika8,
        tekst:"Поглед на Ужички град са истока на фотографији насталој током радова на изградњи старог железничког моста и новог Златиборског пута насталој двадесетих година 20. века",
        tekstEn:"A view of the Old Town in the photo taken from the east side during the construction of the railway bridge and new Zlatibor road in the 1920s",
        tekstDe:"Blick auf die Altstadt von Osten auf einem Foto, das während des Baues der Eisenbahnbrücke und dem neuen Zlatibor-Weg aufgenommen wurde, aufgenommen in den 1920er Jahren",
        tekstRu:"Вид на Старый город с востока на фотографии, сделанной во время строительства железнодорожного моста и новой Златиборской дороги, сделанной в 1920-х годах",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 9,
        slika: Slika9,
        tekst:"Поглед на Ужички град и варош на фотографији насталој тридесетих година 20. века",
        tekstEn:"A view of the City of Užice in the photo taken during the 1930s",
        tekstDe:"Blick auf die Stadt Užice auf einem Foto, die in den dreißiger Jahren des 20. Jahrhunderts aufgenommen wurde",
        tekstRu:"Вид на крепость и город Ужице на фотографии 30-х годов 20 века",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"portret"
    },
    {
        id: 10,
        slika: Slika10,
        tekst:"Поглед на Ужички град и варош на фотографији насталој тридесетих година 20. века",
        tekstEn:"A view of the City of Užice in the photo taken during the 1930s",
        tekstDe:"Blick auf die Stadt Užice auf einem Foto, die in den dreißiger Jahren des 20. Jahrhunderts aufgenommen wurde",
        tekstRu:"Вид на крепость и город Ужице на фотографии 30-х годов 20 века",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
        
    },
    {
        id: 11,
        slika: Slika11,
        tekst:"Поглед на Ужички град са новог Златиборског пута фотографији насталој тридесетих година 20. века",
        tekstEn:"A view of the City of Užice in the photo taken from the new Zlatibor road in the 1930s",
        tekstDe:"Blick auf die Stadt Užice von dem neuen Zlatibor-Weg auf einem Foto aus den dreißiger Jahren des 20. Jahrhunderts",
        tekstRu:"Вид на крепость Ужице с новой Златиборской дороги, фото 30-х годов 20 века",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Историјски архив Ужице",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 12,
        slika: Slika12,
        tekst:"Поглед на Ужички град и хидроцентралу са истока на фотографији насталој између два светска рата",
        tekstEn:"View of the City of Užice and Hydroelectric Power Plant in the photo taken from the east side between the two world wars",
        tekstDe:"Blick auf die Stadt Užice und des Wasserkraftwerks von Osten auf einem Foto, das zwischen den beiden Weltkriegen aufgenommen wurde",
        tekstRu:"Вид на крепость Ужице и гидроэлектростанцию ​​с востока на фотографии, сделанной между двумя мировыми войнами",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 13,
        slika:Slika13,
        tekst:"Поглед на Ужички град и железнички мост са истока на фотографији насталој између два светска рата",
        tekstEn:"View of the City of Užice and the railway bridge in the photo taken from the east side between the two world wars",
        tekstDe:"Blick auf die Stadt Užice und der Eisenbahnbrücke von Osten auf einer Aufnahme zwischen den beiden Weltkriegen",
        tekstRu:"Вид на крепость Ужице и железнодорожный мост с востока на фотографии, сделанной между двумя мировыми войнами",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"portret"
    },
    {
        id: 14,
        slika: Slika14,
        tekst:"Поглед на Ужички град са новог Златиборског пута фотографији из 1928. године",
        tekstEn:"A view of the City of Užice in the photo taken from the new Zlatibor road in 1928",
        tekstDe:"Blick auf die Stadt Užice von dem neuen Zlatibor-Weg, Foto aus dem Jahr 1928",
        tekstRu:"Вид на крепость Ужице с новой Златиборской дороги, фото 1928 г.",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 15,
        slika: Slika15,
        tekst:"Поглед на Ужички град и варош на фотографији насталој тридесетих година 20. века",
        tekstEn:"A view of the City of Užice in the photo taken during the 1930s",
        tekstDe:"Blick auf die Stadt Užice auf einem Foto, die in den dreißiger Jahren des 20. Jahrhunderts aufgenommen wurde",
        tekstRu:"Вид на крепость и город Ужице на фотографии 30-х годов 20 века",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 16,
        slika: Slika16,
        tekst:"Поглед на Ужички град са Читакове стене на фотографији насталој између два светска рата",
        tekstEn:"A view of the City of Užice in the photo taken from Čitak’s rock between the two world wars",
        tekstDe:"Blick auf die Stadt Užice vom Čitak-Felsen auf einem Foto, die zwischen den beiden Weltkriegen aufgenommen wurde",
        tekstRu:"Вид на крепость Ужице со скалы Читакова на фотографии, сделанной между двумя мировыми войнами",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:" Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
    },
    {
        id: 17,
        slika: Slika17,
        tekst:"Поглед на Ужички град са новог Златиборског пута фотографији насталој тридесетих година 20. века",
        tekstEn:"A view of the City of Užice in the photo taken from the new Zlatibor road in the 1930s, Historical Archives of Užice",
        tekstDe:"Blick auf die Stadt Užice von dem neuen Zlatibor-Weg auf einem Foto aus den dreißiger Jahren des 20. Jahrhunderts",
        tekstRu:"Вид на крепость Ужице с новой Златиборской дороги, фото 30-х годов 20 века",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Историјски архив Ужице",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
        
    },
    {
        id: 18,
        slika: Slika18,
        tekst:"Поглед на Ужички град и железнички мост са истока на фотографији насталој између два светска рата",
        tekstEn:"View of the City of Užice and the railway bridge in the photo taken from the east side between the two world wars",
        tekstDe:"Blick auf die Stadt Užice und der Eisenbahnbrücke von Osten auf einer Aufnahme zwischen den beiden Weltkriegen",
        tekstRu:"Вид на крепость Ужице и железнодорожный мост с востока на фотографии, сделанной между двумя мировыми войнами",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"portret"
    },
    {
        id: 19,
        slika: Slika19,
        tekst:"Ужички град са околином на цртежу Аустријанца Феликса Каница из 1860. године, поглед са јужне стране",
        tekstEn:"A view of the City of Užice and its surroundings from the south side as drawn by Felix Kanitz, an Austrian, in 1860",
        tekstDe:"Die Stadt Užice mit ihrer Umgebung auf einer Zeichnung des Österreichers Felix Kanitz aus dem Jahr 1860, Blick von der Südseite",
        tekstRu:"Крепость Ужице с её окрестностями на рисунке австрийца Феликса Каница 1860 г., вид с южной стороны.",
        izvor:"Извор: Историјски архив Ужице",
        izvorEn:"Historical Archives of Užice",
        izvorDe:"Historisches Archiv von Užice",
        izvorRu:"Исторический архив Ужице",
        tip:"landscape"
        
    },
    {
        id: 20,
        slika: Slika20,
        tekst:"Грб породице Војиновић, извор:",
        tekstEn:"Coat of arms of the Vojinović family",
        tekstDe:"Wappen der Familie Vojinović",
        tekstRu:"Герб семьи Воиновичей",
        izvor: "wikipedija",
        izvorEn:"Wikipedia",
        izvorDe:"Wikipedia",
        izvorRu:"Wikipedia",
        src: "https://sr.wikipedia.org/wiki/%D0%92%D0%BE%D1%98%D0%B8%D0%BD%D0%BE%D0%B2%D0%B8%D1%9B%D0%B8",
        tip:"portret"
    },
    {
        id: 21,
        slika: Slika21,
        tekst:"Изглед Старог града Ужица пре реконструкције горњег града",
        tekstEn:"Old Town of Užice before Upper Town reconstruction",
        tekstDe:"",
        tekstRu:"",
        izvor:"Извор: Ужички лексикон",
        izvorEn:"Source: lexicon",
        izvorDe:"",
        izvorRu:"",
        tip:"portret"
    },
    {
        id: 22,
        slika: Slika22,
        tekst:"Цитадела са кулом након реконструкције горњег града Извор: ",
        tekstEn:"Citadel with Tower after Upper Town reconstruction",
        tekstDe:"",
        tekstRu:"",
        izvor: "telegraf.rs",
        izvorEn:"telegraf.rs",
        izvorDe:"telegraf.rs",
        izvorRu:"telegraf.rs",
        src: "https://www.telegraf.rs/putovanja/ja-volim-srbiju/3395701-simbol-uzica-dobija-novi-izgled-rekonstrukcija-i-konzervacija-grada sagradjenog-na-litici-iznad-reke-djetinje?galerija=1&amp;slika=2",
        tip:"landscape"
    },
    {
        id: 23,
        slika: Slika23,
        tekst:"Модел Николе Алтомановића израђен за потребе апликације",
        tekstEn:"Model of Nikola Altomanović",
        tekstDe:"",
        tekstRu:"",
        izvor:"Autor",
        tip:"portret"
    },
    {
        id: 24,
        slika: Slika24,
        tekst:"Скица 1. Стари град Ужице у другој половини XIV века",
        tekstEn:"Sketch 1. Old Town of Užice in second half of 14 th century",
        tekstDe:"Skizze 1. Die Altstadt von Užice in der zweiten Hälfte des 14.Jhs.",
        tekstRu:"Эскиз 1. Старый город Ужице во второй половине 14 века",
        izvor:" Извор: Поповић, М. (2004). Стари град Ужице, стр. 87",
        izvorEn:"Source: Popović, M. (2004). Stari grad Užice, str. 87",
        izvorDe:"Quelle: Popović, М. (2004). Die Altstadt Užice, S. 87",
        izvorRu:"Источник: Поповић, М. (2004). Стари град Ужице, стр. 87",
        tip:"portret"
    },
    {
        id: 25,
        slika:Slika25,
        tekst:"Скица 2. Замишљени изглед цитаделе са кулом",
        tekstEn:"Sketch 2. Imaginary picture of citadel with tower",
        tekstDe:"Skizze 2. Imaginäre Ansicht einer Zitadelle mit Turm",
        tekstRu:"Эскиз 2. Воображаемый вид цитадели с башней",
        izvor:"Извор: Поповић, М. (2004). Стари град Ужице, стр. 93",
        izvorEn:"Source: Popović, M. (2004). Stari grad Užice, str. 93",
        izvorDe:"Quelle: Popović, М. (2004). Die Altestadt Užice, S. 93",
        izvorRu:"Источник: Поповић, М. (2004). Стари град Ужице, стр. 93",   
        tip:"portret"
    },
    {
        id: 26,
        slika: Slika26,
        tekst:"Скица 3. Изглед цитаделе и унутрашњи пресек куле",
        tekstEn:"Sketch 3. External appearance of citadel and cross-section of tower interior",
        tekstDe:"Skizze 3. Blick auf die Zitadelle und das Innere des Turms",
        tekstRu:"Эскиз 3. План цитадели и внутреннего разреза башни",
        izvor: "Извор: Поповић, М. (2004). Стари град Ужице, стр. 83",
        izvorEn:"Source: Popović, M. (2004). Stari grad Užice, str. 83",
        izvorDe:"Quelle: Popović, М. (2004). Die Altestadt Užice, S. 83",
        izvorRu:"Источник: Поповић, М. (2004). Стари град Ужице, стр. 83",
        tip:"portret"
    },
    {
        id: 27,
        slika:Slika27,
        tekst:"Скица 4. Изглед полукружне куле",
        tekstEn:"Sketch 4. External appearance of semi-circular tower",
        tekstDe:"Skizze 4. Das Aussehen des halbrunden Turms",
        tekstRu:"Эскиз 4. Схема полукруглой башни",
        izvor:"Извор: ПоповићМ. (2004). Стари град Ужице, стр. 82",
        izvorEn:"Source: Popović, M. (2004). Stari grad Užice, str. 82",
        izvorDe:"Quelle: Popović, М. (2004). Die Altestadt Užice, S. 82",
        izvorRu:"Источник: Поповић, М. (2004). Стари град Ужице, стр. 82",
        tip:"portret"
    }
  ];
  
  export default GalerijaLista;