import Carousel1 from '../../carousel/carousel1.png';
import Carousel2 from '../../carousel/carousel2.png';
import Carousel3 from '../../carousel/carousel3.png';
import Carousel4 from '../../carousel/carousel4.png';
import Video from '../../carousel/video.mp4';
const Lista = [
    {
        id: 1,
        slika: Video,
        tip:"video"
    },
    {
        id: 2,
        slika: Carousel1,
        tip:"slika"
    },
    {
        id: 3,
        slika: Carousel2,
        tip:"slika"
    },
    {
        id: 4,
        slika: Carousel3,
        tip:"slika"
    },
    {
        id: 5,
        slika: Carousel4,
        tip:"slika"
    },
    
  ];
  
  export default Lista;